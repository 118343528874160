

//import truck from '../ContactImg/roadMapIcon.svg';

import icon from '../../images/w3oBlackLogo.png';


let rm1 = { background: "linear-gradient(to bottom, #FFFFFF, #000000)" };
let rm2 = { background: "linear-gradient(to bottom, #CCCCCC, #000000)" };
let rm3 = { background: "linear-gradient(to bottom, #AAAAAA, #000000)" };
let rm4 = { background: "linear-gradient(to bottom, #888888, #000000)" };
let rm5 = { background: "linear-gradient(to bottom, #666666, #000000)" };
let rm6 = { background: "linear-gradient(to bottom, #444444, #000000)" };
let rm7 = { background: "linear-gradient(to bottom, #222222, #000000)" };
let rm8 = { background: "linear-gradient(to bottom, #000000, #000000)" };





let utilitiesElements = [
    {
      id: 1,
      per: "20%",
      Header: "BECOME A DAO MEMBER:",
      TopLine: "",
      description:"Join our decentralized collective and have a real say in the direction of Wardrobe X. Unlike traditional companies, every member has a voice.",
      //buttonText: "",
      trans: true,
      text: true,
      icon: {icon},
      style: 0,
      date: "20%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Join+the+DAO.png',
      col: "linear-gradient(to bottom, #FFFFFF, #000000)"
    },
    {
      id: 2,
      per: "40%",
      Header: "EARN COMMISSION",
      TopLine: "",
      description:"Members benefit financially from our success. Earn commission on sales and transactions you facilitate or contribute to.",
      //buttonText: "",
      trans: false,
      text: false,
      icon: {icon},
      style: 0,
      date: "40%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Earn+Commisions.png',
      col: "linear-gradient(to bottom, #CCCCCC, #000000)"
    },
    {
      id: 3,
      per: "20%",
      Header: "SHARED IP RIGHTS & TRANSPARENT OPERATIONS",
      TopLine: "",
      description:"Your contributions can earn you a stake in the properties we develop. Alongside this, enjoy full transparency in company earnings and operations, ensuring trust and accountability within our community.",
      //buttonText: "",
      trans: true,
      text: true,
      icon: {icon},
      style: 0,
      date: "20%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Transparent+Earnings.png',
      col: "linear-gradient(to bottom, #AAAAAA, #000000)"
    },
    {
      id: 4,
      per: "40%",
      Header: "DEVELOP PARTNERSHIPS",
      TopLine: "",
      description:"Use your network to forge valuable partnerships. As a member, you can initiate and cultivate relationships that benefit the whole DAO.",
      //buttonText: "",
      trans: false,
      text: false,
      icon: {icon},
      style: 0,
      date: "40%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Partnerships.png',
      col: "linear-gradient(to bottom, #888888, #000000)"
    },
    {
      id: 5,
      per: "60%",
      Header: "PARTICIPATE IN GOVERNANCE",
      TopLine: "",
      description:"Vote on company decisions, from strategic moves to daily operations. Your vote counts in every aspect of our business.",
      //buttonText: "",
      trans: true,
      text: true,
      icon: {icon},
      style: 0,
      date: "60%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Governance.png',
      col: "linear-gradient(to bottom, #666666, #000000)"
    },
    {
      id: 6,
      per: "80%",
      Header: "EXCLUSIVE MEMBER BENEFITS",
      TopLine: "",
      description:"Access members-only discounts, special offers, and early releases for all our products and collaborations.",
      trans: false,
      text: false,
      icon: {icon},
      style: 0,
      date: "80%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+Exclusive+Benefits.png',
      col: "linear-gradient(to bottom, #444444, #000000)"
    },
    {
      id: 7,
      per: "100%",
      Header: "GROW YOUR SKILLS",
      TopLine: "The final verdict. Its moving time!",
      description: "With resources and a community at your fingertips, hone your expertise in the Web 3.0 space.",
      trans: true,
      text: true,
      icon: {icon},
      style: 0,
      date: "100%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Growth.png',
      col: "linear-gradient(to bottom, #222222, #000000)"
    },
    {
      id: 8,
      per: "110%",
      Header: "NETWORK AND COMMUNITY",
      TopLine: "Congratulations on successfully completing your move!",
      description: "Connect with like-minded individuals and industry leaders in a dynamic DAO community. Gain support for personal and collective growth, empowering your aspirations and our shared goals.",
      trans: false,
      text: false,
      icon: {icon},
      style: 0,
      date: "110%",
     
      img: 'https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3o+Dao+How+It+Works/Web+3+Outfitters+-+Network+and+Community.png',
      col: "linear-gradient(to bottom, #000000, #000000)"
    }
    
  ];
  
 export const timelineData = {
    event1: {
      date: '2023-01-15',
      title: 'Event 1',
      subtitle: 'Subtitle 1',
      description: 'Description for Event 1...',
    },
    event2: {
      date: '2023-02-20',
      title: 'Event 2',
      subtitle: 'Subtitle 2',
      description: 'Description for Event 2...',
    },
    // Add more events as needed
  };


  export default utilitiesElements;