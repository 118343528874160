export const itemsInfo = [
    {
      name: "Ethereal Sovereign",
      role: "CEO",
      bio: "The visionary behind our crypto odyssey, steering the ship toward blockchain's brightest galaxies.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team1.png"
    },
    {
      name: "BleekedChain Enigma",
      role: "BLOCKCHAIN DEV",
      bio: "Cracking the cryptographic codes, crafting the future of decentralized dreams.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team2.png"
    },
    {
      name: "Chroma Magus",
      role: "GRAPHIC DESIGNER",
      bio: "Weaving blockchain's vivid tapestry through pixel and code incantations.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team3.png"
    },
    {
      name: "Code Conjurer",
      role: "FULLSTACK DEV",
      bio: "Summoning the magic of both frontend and backend realms to conjure crypto marvels.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team4.png"
    },
    {
      name: "Dimensional Artisan",
      role: "3D MODELER",
      bio: "Sculpting digital dimensions where NFTs come to life in crypto wonderlands.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team5.png"
    },
    {
      name: "Cryptic Enchanter",
      role: "WARDROBE X ATTORNEY",
      bio: "Mastering the legal spells of Web 3.0, safeguarding our crypto realms.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team6.png"
    },
    {
      name: "Realm Guardian",
      role: "COMMUNITY MANAGER",
      bio: "Guiding our vibrant crypto community through the ever-shifting blockchain landscapes.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team7.png"
    },
    {
      name: "Taskmaster of the Ether",
      role: "QUALITY CONTROL",
      bio: "Ensuring our crypto endeavors remain on course, vanquishing task dragons.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team8.png"
    },
    {
      name: "Hawaiian Diviner",
      role: "BACKEND TESTER",
      bio: "Unraveling the intricacies of blockchain data, seeking hidden treasures of quality.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team9.png"
    },
    {
      name: "Chopped Chungus Amungus",
      role: "Frontend Developer",
      bio: "Crafting pixel spells that enchant users into seamless crypto experiences.",
      imageUrl:  "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oTeamImages/team10.png"
    }
  ];
  