import styled, { keyframes } from 'styled-components';

import { motion } from 'framer-motion';
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           ProgressBar ELEMENTS                                                                                    ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const progressBarBgColor = '#cc5511';
const progressBarCompleteBgColor = '#cc5500';
const progressBarLiquidStartColor = '#cc5599';
const progressBarLiquidEndColor = '#cc5522';
const progressBarLiquidSize = '70px';
const progressBarAnimationDuration = '2500ms';

const progressbarGAnimation = keyframes`
  0% {
    background-color: ${progressBarCompleteBgColor};
  }
  50% {
    background-color: #607e9e;
  }
  100% {
    background-color: ${progressBarCompleteBgColor};
  }
`;

const progressbarRAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ProgressBarSection = styled.div`
  max-width: 750px;
  margin: px auto;
  h1 {
    text-align: center;
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
  font-size: 18px;
  font-weight: 800;
  font-family: "inter";
  height: 30px;
  margin-top: 50px;
  //border: 1px solid ${progressBarBgColor};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ProgressBarContainer2_Original = styled.div`
  position: relative;
  width: 100%;
  font-size: 18px;
  font-weight: 800;
  font-family: "inter";
  height: 30px;
  margin-top: 40px;
  border: 1px solid ${progressBarBgColor};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ProgressBarComplete = styled.div`
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: ${progressBarCompleteBgColor};
  border-radius: 10px;
  animation: ${progressbarGAnimation} ${progressBarAnimationDuration} infinite ease-in-out;
  z-index: 2;
`;

export const ProgressBarLiquid = styled.div`
  z-index: 1;
  height: ${progressBarLiquidSize};
  animation: ${progressbarGAnimation} ${progressBarAnimationDuration} infinite ease-in-out,
    ${progressbarRAnimation} ${progressBarAnimationDuration} infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background: linear-gradient(to bottom right, ${progressBarLiquidStartColor}, ${progressBarLiquidEndColor});

  border-radius: 40%;
`;



export const Progress = styled.div`
  z-index: 2;
`;


export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }
`;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Main Animation                                                                                           ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const PreloaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999999999999999;
  //background-color: black;
  
`;

export const PreloaderWrapper = styled.div`
  ///background-color: #313142;
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: 9999999999;
  //background-color: black;
  transition: visibility 1s ease, transform 1s ease;
 
`;




export const Preloader = styled.div`
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;


export const Loader = styled(motion.div)`
  position: absolute;
  top: 40%;
  width: 100%;

  padding: 150px;
  z-index: 99999999999999999999999;
  @media screen and (max-width: 870px)  {
       // margin-left: 50px;
      zoom: 0.8;
    }

`;
export const notLeftAnimation = keyframes`
  50% {
    left: 50%;
  }
  100% {
    left: 120%;
  }
`;

export const DotWrap = styled.div`
filter: drop-shadow(0 0 10px white);


`
export const Dot = styled.img`
  position: absolute;
  left: 0;
  width: 35px;
  height: 35px;
  background-color: white;
  box-shadow: 0 0 10px white;
  /* Webkit Prefix */
  -webkit-box-shadow: 0 0 10px white;

  border-radius: 50%;
  z-index: 9999999;
  zoom: 2;
  //-webkit-transform: translate3d(0,0,0)
  animation: ${notLeftAnimation} 4.25s ease infinite;

  &:nth-child(1) {
    animation-delay: 0.25s;
    z-index: 999999;

  }
  &:nth-child(2) {
    animation-delay: 0.3s;
    z-index: 99999;
    transform: scale(0.9);
  }

  &:nth-child(3) {
    animation-delay: 0.35s;
    z-index: 9999;
    transform: scale(0.8);
  }

  &:nth-child(4) {
    animation-delay: 0.4s;
    z-index: 999;
    transform: scale(0.7);
  }

  &:nth-child(5) {
    animation-delay: 0.45s;
    z-index: 99;
    transform: scale(0.6);
  }
  &:nth-child(6) {
    animation-delay: 0.5s;
    z-index: 9;
    transform: scale(0.45);
  }

  @media screen and (max-width: 870px)  {
    &:nth-child(1) {
    animation-delay: 0.3s;
    z-index: 999999;

  }
  &:nth-child(2) {
    animation-delay: 0.4s;
    z-index: 99999;
    transform: scale(0.9);
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
    z-index: 9999;
    transform: scale(0.8);
  }

  &:nth-child(4) {
    animation-delay: 0.6s;
    z-index: 999;
    transform: scale(0.7);
  }

  &:nth-child(5) {
    animation-delay: 0.7s;
    z-index: 99;
    transform: scale(0.6);
  }
  &:nth-child(6) {
    animation-delay: .8s;
    z-index: 9;
    transform: scale(0.45);
  }
    }
 
`;





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           Loading ELEMENTS                                                                                        ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




// Container styles
export const Container = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 100px;
  top: 75%;
  //margin-top: -62.5px;
  font-family: monospace;
  color: white;
  text-transform: uppercase;



  @media screen and (max-width: 1200px) {
       zoom: 0.9;
  
  

    }
    @media screen and (max-width: 960px) {
       zoom: 0.8;
  
    }

    @media screen and (max-width: 768px) {
 
     
        zoom: 0.7;
         }

    @media screen and (max-width: 550px) {
       zoom: 0.6;
  
  

    }
    @media screen and (max-width: 550px) {
       zoom: 0.5;
  
    }
`;

// Animation keyframes
const wawi = keyframes`
  0% {
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  50% {
    text-shadow: 0 0 200px rgba(0, 0, 0, 0.7), 0 0 200px rgba(0, 0, 0, 0.7), 0 0 200px rgba(0, 0, 0, 0.7);
    color: rgba(0, 0, 0, 0.8);
  }
  100% {
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`;

const wawi2 = keyframes`
  0% {
    color: rgba(192, 192, 192, 0.8);
    text-shadow: 0 0 200px rgba(192, 192, 192, 0.8), 0 0 200px rgba(192, 192, 192, 0.8), 0 0 200px rgba(192, 192, 192, 0.8);
  }
  50% {
    color: rgba(192, 192, 192, 0.4);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  100% {
    color: rgba(192, 192, 192, 0.8);
    text-shadow: 0 0 200px rgba(192, 192, 192, 0.8), 0 0 200px rgba(192, 192, 192, 0.8), 0 0 200px rgba(192, 192, 192, 0.8);
  }
`;
const waw3 = keyframes`
  0% {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  }
  50% {
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 20px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 20px rgba(0, 0, 0, 0.7));
  }
  100% {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  }
`;

const wawi4 = keyframes`
  0% {
    filter: drop-shadow(0 0 20px rgba(192, 192, 192, 0.8)) drop-shadow(0 0 20px rgba(192, 192, 192, 0.8)) drop-shadow(0 0 20px rgba(192, 192, 192, 0.8));
  }
  50% {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  }
  100% {
    filter: drop-shadow(0 0 20px rgba(192, 192, 192, 0.8)) drop-shadow(0 0 20px rgba(192, 192, 192, 0.8)) drop-shadow(0 0 20px rgba(192, 192, 192, 0.8));
  }
`;

// Rest of the code remains the same...

// Additional class styles
export const BrightyBlow = styled.div`
  color: rgba(192, 192, 192, 0.4);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: ${wawi2} 2s infinite linear;
`;

export const BrightyBlack = styled.div`
  color: rgba(0, 0, 0, 0.4);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
font-size: 64px;
  font-family: "inter";
  animation: ${wawi} 2s infinite linear;
`;









/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                         Circular Progress                                                                                           ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



export const ProgressCircContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60%;
  zoom: 0.8;
`;

export const ProgressCircle = styled.circle`
  fill: transparent;
  stroke: hsla(225, 20%, 92%, 0.9);
  stroke-linecap: round;
`;

export const FilledCircle = styled.circle`
fill: transparent;
stroke: #cc5500;
transform: rotate(-90deg);
transform-origin: 50% 50%;
transition: stroke-dashoffset 0.5s ease-out;
stroke-dasharray: ${({ circumference }) => `${circumference} ${circumference}`};
stroke-dashoffset: ${({ offset }) => offset};
`;

export const ProgressText = styled.div`
  align-items: center;
  //color: hsla(225, 23%, 62%, 1);
  display: flex;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  left: 0;
  letter-spacing: -0.225em;
  transform: matrix(2, 0, 0, 1.8, 0, 0);
  margin-bottom: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  color: white;
`;





///
/// Closet Slide Background animation
///


export const Curtain = styled.div`
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  //overflow: hidden;
`;

export const Wrapper = styled.div`


  input[type='checkbox'] {
    display:inline-block; 
     vertical-align:middle;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    top: 0;
    left: 0;

    &:checked {
      & ~ .curtain__panel--left {
        transform: translateX(0);
      }

      & ~ .curtain__panel--right {
        transform: translateX(0);
      }
    }
  }
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
 // background: black;
  //color: #fff;
  float: left;
  position: absolute;
  width: 100vw;
left: 0%;
  height: 100vh;
  transition: all 1s ease-out;
  z-index: 2;
  //overflow: auto;

  &--left {
    justify-content: flex-end;
    transform: translateX(-100%);
  }

  &--right {
    justify-content: flex-start;
    transform: translateX(100%);
  }
`;

export const Panel2 = styled.div`
  display: flex;
  align-items: center;
  background: black;
  color: #fff;
  float: left;
  position: absolute;
  width: 50vw;
right: 0%;
  height: 100vh;
  transition: all 1s ease-out;
  z-index: 2;
  //overflow: auto;

  &--left {
    justify-content: flex-end;
    transform: translateX(-100%);
  }

  &--right {
    justify-content: flex-start;
    transform: translateX(100%);
  }
`;

export const Content = styled.div`



`;

export const PreloadLogo = styled.img`
  width: 100%;
  height: 100%;
 transform : scale(0.75);
 -webkit-perspective:700px;
  perspective: 700px;

  transform-origin:0 70%;
  transform-style:preserve-3d;
  -webkit-transform-style:preserve-3d;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  @media screen and (max-width: 1200px) {
  
    transform: scale(0.65);
  }
  @media screen and (max-width: 960px) {

    transform: scale(0.55);
  }
  @media screen and (max-width: 768px) {
   
    transform: scale(0.45);
  }
  &:hover {
  }
`;

export const PreloadLogoCont = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
top: 30%;
 left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999999;
  text-decoration: none;
  &:hover {
    box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
  @media screen and (max-width: 1200px) {
  
  transform: translate(-50%, -50%) scale(0.50);
}
@media screen and (max-width: 960px) {

  transform: translate(-50%, -50%) scale(0.35);
}
@media screen and (max-width: 768px) {
 
  transform: translate(-50%, -20%) scale(0.25);
}
`;

export const LogoName = styled(motion.div)`
  display: flex;
  align-items: center;

  color: #fff;
  float: left;
  position: absolute;
  zoom: 0.25;
  height: 100vh;
  transition: all 1s ease-out;
  z-index: 2;
  left: 0;
  

  transform-origin: right center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);


`;

export const LogoName2 = styled(motion.div)`
  display: flex;
  align-items: center;
  -webkit-perspective:700px;
  perspective: 700px;
  color: #fff;
  float: left;
  position: absolute;
  zoom: 0.25;
  height: 100vh;
  transition: all 1s ease-out;
  z-index: 3;
  //left: 30px;
  transform-origin: left center; /* Set the transform origin to the left edge */
  //transform: scale(0.85);

`;



///
/// Split Image
///

export const SpitImageContainerWrap = styled.div`

display: grid;
  place-items: center;
 // height: 100vh;

  `
export const SplitImageContainer = styled(motion.div)`

position: absolute;
z-index: 9999999999999;
  width: 1295px;
  height: 180px;
  display: flex;
`;

// Define the individual split image style
export const SplitImage = styled.span`
  width: 647.5px;
  height: 215px;
  
 transform : scale(0.75);
 -webkit-perspective:700px;
  perspective: 700px;

  transform-origin:0 70%;
  transform-style:preserve-3d;
  -webkit-transform-style:preserve-3d;
  object-fit: cover; /* Ensure the image fits the container without overflowing */
  cursor: pointer;
  pointer-events: none;
  //background: url("../../images/w3oNameHorzWhite.png");
  background-size: cover;
  transition: all 400ms ease;

  &:nth-child(2) {
    background-position-x: -647.5px;


  }
  

`;

// Apply styles to the second split image
export const SplitImage2 = styled(SplitImage)`

`;

// Add styles for hover effect on all split images
export const SplitImageContainerHover = styled(SplitImageContainer)`
  &:hover ${SplitImage} {
    margin: 0 24px;
    transform: scale(1.08) rotateZ(3deg);
    box-shadow: 0 5px 40px -24px #00ffdd;
  }
`;
